import {QueryClient, QueryClientProvider, useQuery, useQueryClient,} from '@tanstack/react-query'
import React, {useState} from "react";
import {fetchAllStatuses, fetchLogs} from './utils/data-utils';
import Modal from './Modal'

// Create a client
const queryClient = new QueryClient()

function App() {
    return (// Provide the client to your App
        <QueryClientProvider client={queryClient}>
            <StatusTable/>
        </QueryClientProvider>)
}

function StatusTable() {
    // Access the client
    const queryClient = useQueryClient()
    const [logId, setLogId] = useState("");

    // Queries
    const query = useQuery({queryKey: ['allStatus'], queryFn: fetchAllStatuses, refetchInterval: 10000});
    const queryLog = useQuery({queryKey: ['log', logId], queryFn: () => fetchLogs(logId)});
  
    const consoleLink = "LACONIC_HOSTED_CONFIG_app_console_link";

    return (<div>
        {logId && (<Modal open={true}>
                <button className="close" onClick={() => setLogId("")}>CLOSE</button>
                <div className="log-data">
                    <pre>{queryLog.data}</pre>
                </div>
            </Modal>)}
        <div id="all-status">
            {query.data?.map((status: any) => <div className={status.lastState.toLowerCase() + " status-row"}>
                <div className="status-line">
                    <span className="status-key">Request ID:</span>
                    <span className="status-value"><a href={consoleLink.replace("#RQID#", status.id)}>{status.id}</a></span>
                </div>
                <div className="status-line">
                    <span className="status-key">Application:</span>
                    <span className="status-value">{status.app}</span>
                </div>
                {status.deployment && <div className="status-line">
                    <span className="status-key">URL:</span>
                    <span className="status-value"><a href={status.url}>{status.url}</a></span>
                </div>}
                <div className="status-line">
                    <span className="status-key">Last Update:</span>
                    <span className="status-value">{status.lastUpdate}</span>
                </div>
                <div className="status-line">
                    <span className="status-key">Last State:</span>
                    <span className="status-value">{(status.logAvailable &&
                        <button className={"button-" + status.lastState.toLowerCase()}
                                onClick={() => {
                                    queryClient.invalidateQueries({queryKey: ["log"]}).then(() => setLogId(status.id))
                                }}>{status.lastState}
                        </button>) || status.lastState}</span>
                </div>
            </div>)}
        </div>
    </div>)
}

export default App;
