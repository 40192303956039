import {Component} from "react";

const MODAL_STYLES = {
    position: "absolute" as "absolute",
    backgroundColor: "#FFF",
    marginTop: "2em",
    padding: "2em",
    zIndex: "1000",
    width: "75%",
    height: "85%",
    borderRadius: ".5em",
};
const OVERLAY_STYLE = {
    position: "fixed" as "fixed",
    display: "flex",
    justifyContent: "center",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
};

class Modal extends Component<{ open: any, children: any }> {
    render() {
        let {open, children} = this.props;
        if (!open) return null;
        return (<>
                <div style={OVERLAY_STYLE}>
                    <div style={MODAL_STYLES}>{children}</div>
                </div>
            </>);
    }
}

export default Modal;