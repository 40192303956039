const apiUrl = "LACONIC_HOSTED_CONFIG_app_api_url";

export const fetchAllStatuses = async () => {
    const res = await fetch(apiUrl);
    if (!res.ok) {
        throw new Error('Network response was not ok')
    }
    return await res.json();
}


export const fetchLogs = async (id: string) => {
    if (!id) {
        return "";
    }
    let url = `${apiUrl}/log/${id}`
    const res = await fetch(url);
    if (!res.ok) {
        throw new Error('Network response was not ok')
    }
    return await res.text();
}
